<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col
              cols="12"
              class="pl-0 pr-0 pb-1 mt-10"
            >
              <v-row>
                <v-spacer />

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="santral"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="startDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="3"
                  sm="4"
                  md="4"
                  class="pr-3"
                >
                  <v-slider
                    v-model="hoursModel"
                    :thumb-size="50"
                    thumb-label="always"
                  >
                    {{ hoursModel }}
                    <template v-slot:thumb-label="{value}">
                      {{ hourSelected[Math.min(Math.floor(value * 2))] }}
                    </template>
                  </v-slider>
                </v-col>

                <v-col
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-btn
                    color="primary"
                    @click="getDailyMinuteEnergy"
                  >
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              :key="componentKey"
              cols="12"
            >
              <echart
                id="invertAnalysis"
                class-name="widget-content"
                height="350px"
                width="100%"
                :chart-data="livehourdata"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as Timeofinverter from '@/api/Inverter/Timeofinverter';
import moment from 'moment';
import Echart from '@/views/components/Echart/Chart.vue';
import i18n from '@/locale';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  data: () => ({
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),

    menu: false,
    modal: false,
    menu2: false,
    livehourdata: {},
    inverterList: {},
    measureList: [],
    hoursModel: 50,
    hourSelected: [],
    componentKey: 1,
    inverterPowerData: {},
    santral: '',
    santralInfo: [],
     riseze: "false",
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      return santralDataList;
    },
  },
  watch: {
    hoursModel() {
      this.get_InverterTimeAcPowers();
    },
    $route: function (from, to) {
      this.riseze = "true";
    },
  },
  created() {
  },
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;
    function myTimer1() {
      const StatsMeasure = self.$store.getters.santralInfo;
      const stepTime = self.$store.getters.stepTime;
      const { santral } = self.$store.getters.selectedSantralModel;

      if (
        Object.keys(StatsMeasure).length > 0
        && StatsMeasure !== undefined
        && santral !== undefined
      ) {
        self.santral = santral;
        self.santralInfo = StatsMeasure;

        const timeList = [];
        const selectİndex = 50;

        for (let h = 5; h < 22; h++) {
          for (let mn = 0; mn < 60; mn += 5) {
            let hour;
            let mnt;

            h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
            mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);
            if (timeList.length < 201) {
              timeList.push(`${hour}:${mnt}`);
            }
          }
        }

        self.hoursModel = selectİndex;
        self.hourSelected = timeList;
        self.getDailyMinuteEnergy();
        clearInterval(myVar1);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          column: [
            'ACPower',
            'inverter',
            'INVERTERNAME',
            'INVERTERKEY',
            'INVERTER',
            'santral',
            'date',
            'datetimestamp',
            'PerformanceRatio',
          ],
        },
      };

      const tempData = Timeofinverter.default.getInverterTimeAcPower(params);
      tempData.then((res) => {
        this.componentKey += 1;
        this.inverterPowerData = res;
        this.get_InverterTimeAcPowers();
      });
    },

    get_InverterTimeAcPowers() {
      const inverterDcPower = {};
      const inverterList = this.santralInfo[this.santral].INVERTER;
     
      Object.keys(inverterList).forEach((item) => {
        if (inverterList[item].hasOwnProperty('INVERTERNAME')) {
          const val = inverterList[item].installedDcPower;
          const key = inverterList[item].INVERTERNAME;
          inverterDcPower[key] = val;
        } else {
          const val = inverterList[item].installedDcPower;
          const key = inverterList[item].INVERTER;
          inverterDcPower[key] = val;
        }
      });

      const res = this.inverterPowerData;
      this.measureList = [
        { value: 'ACPower', text: i18n.t('ACPower'), unit: 'kWh' },
        { value: 'INVERTERNAME', text: 'Inverter DC Güçü', unit: '%' },
      ];

      const hoursModel = this.hoursModel * 2;
      const select = this.hourSelected[hoursModel];

      const optionData = Timeofinverter.default.get_InverterTimeAcPowers(
        res[select],
        this.measureList,
        inverterDcPower,
      );
      optionData.then((result) => {
        this.livehourdata = result;
        this.componentKey += 1;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
